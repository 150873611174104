<template>
  <HeaderComponent class="header">
    <div class="icon-container">
      <img class="icon" v-for="item in icons" :key="item.icon" :src="item.icon" @click="handleClick(item.event)" />
    </div>
  </HeaderComponent>
</template>

<script>
import HeaderComponent from "@/components/header/index.vue"

export default {
  components: {
    HeaderComponent,
  },
  props: {
    icons: {
      type: Array,
      default: ()=>{
        return [
          {
            icon: '',
            event: ''
          }
        ]
      }
    }
  },
  data() {
    return {

    }
  },
  methods: {
    handleClick(eventName) {
      this.$emit(eventName)
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  .icon-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .icon {
      width: 15px;
      height: 15px;
      margin-right: 24px;
    }
  } 
}
</style>