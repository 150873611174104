<template>
  <div class="map-trace">
    <Header />

    <!-- 地图 -->
    <div class="map-container" id="mapContainer">
      
    </div>
  </div>
</template>

<script>
import {fetchOrderDetail, routerPath} from '@/api/order'
import {initMap, getZoom, playMapTraceLine, addMapMarkerCustom,addMapInfoWindow} from '@/utils/map';
import Header from '@/components/header/index.vue';



export default {
  components: {
    Header,
  },
  data() { 
    return {
      mapData:{
        center: {lng: 108.5525,lat:34.3277},
        zoom: 5,
        height: '80vh',
        startMarker: {
          icon: require('../../assets/images/map/start.png'),
        },

        carMarker: {
          show: true,
          icon: require('../../assets/images/map/car.png'),
        },
        endMarker: {
          show: false,
          icon: require('../../assets/images/map/end.png'),
          lng: '',
          lat: ''
        },
        carNumber: '陕YH0008',
        carNumberColor: '2',
        startRegionCode: '',
        endRegionCode: '',
        endAddress: '',
        sapOrderCode: '',
        pzCode: '',
        outTime: '2021-12-27 12:13:00',
        signTime: null,
        lineList:[{
          'lng': 116.297611,
          'lat': 40.047363
        }, {
          'lng': 116.302839,
          'lat': 40.048219
        }, {
          'lng': 116.308301,
          'lat': 40.050566
        }, {
          'lng': 116.305732,
          'lat': 40.054957
        }, {
          'lng': 116.304754,
          'lat': 40.057953
        }, {
          'lng': 116.306487,
          'lat': 40.058312
        },
          /* {
           'lng': 118.683787,
           'lat': 34.920526
         }*/
        ],
      },
      infoWindow: {
        show: false,
        showCar: false,
        showEnd: false,
        info:{
          factory: "未知",
          carNumber: "未知",
          outTime: "未知",
          driverName: "未知",
          driverPhone: "未知",
          address: "未知",
          arriveTime: "未知"
        },
      },
    }
  },
  async mounted() {
    const res = await initMap('mapContainer');
    this.map = res.map;
    var orderId = this.$route.params.orderId;
    let orderInfo = await fetchOrderDetail({id: orderId})
    this.infoWindow.info.factory = orderInfo.data.factoryName;
    this.mapData.endRegionCode =orderInfo.data.areaCode;
    this.mapData.endAddress = orderInfo.data.provinceName+orderInfo.data.cityName+orderInfo.data.areaName+orderInfo.data.tranAddress;
    this.infoWindow.info.endAddress = orderInfo.data.provinceName+orderInfo.data.cityName+orderInfo.data.areaName+orderInfo.data.tranAddress;

    
    if (undefined != this.$route.params.row.signTime && null != this.$route.params.row.signTime && '' != this.$route.params.row.signTime) {
      this.mapData.carMarker.show = false;
    }
    
    this.mapData.carNumber = this.$route.params.row.carNumber;
    this.infoWindow.info.carNumber = this.$route.params.row.carNumber;
    this.mapData.outTime = this.$route.params.row.outTime;
    this.mapData.signTime = this.$route.params.row.signTime;
    this.mapData.startRegionCode = this.$route.params.row.beginCounty;
    // this.mapData.endRegionCode = this.$route.params.row.endCounty;
    // this.mapData.endAddress = this.$route.params.row.endAddr;
    this.mapData.sapOrderCode = this.$route.params.row.erpId;
    this.mapData.pzCode = this.$route.params.row.pzCode;
    this.infoWindow.info.outTime = this.$route.params.row.outTime;
    this.infoWindow.info.driverName = this.$route.params.row.driverName;
    this.infoWindow.info.driverPhone = this.$route.params.row.phone;
    // this.infoWindow.info.endAddress = this.$route.params.endAddr;
    this.infoWindow.info.signTime = this.$route.params.row.signTime;


    this.handleMap();

    
    
    
  },
  methods: {
    async handleMap() {
      try {
        const response = await routerPath({
          vclN: this.mapData.carNumber,
          // vco: this.mapData.carNumberColor,
          vco: '2',
          qryBtm: this.mapData.outTime,
          qryEtm: this.mapData.signTime,
          startRegionCode: this.mapData.startRegionCode,
          endRegionCode: this.mapData.endRegionCode,
          endAddress: this.mapData.endAddress,
          sapOrderCode: this.mapData.sapOrderCode,
          pzCode: this.mapData.pzCode,

          // vclN: '陕YH0008',
          // vco: '2',
          // qryBtm: '2021-12-27 12:13:00',
          // endAddress: '山东省临沂市莒南县团林镇南唐楼',
        });
        if (response.code == 200) {
          if (undefined != response.data.startLng && null != response.data.startLng && '' != response.data.startLng &&
              undefined != response.data.endLat && null != response.data.endLat && '' != response.data.endLat) {
            this.mapData.endMarker.show = true;
            this.mapData.endMarker.lng = response.data.startLng;
            this.mapData.endMarker.lat = response.data.endLat;
          }

          this.mapData.lineList = [];
          var trackArray = response.data.trackArray;
          trackArray.forEach((item) => {
            this.mapData.lineList.push({
              lng: parseFloat(parseInt(item.lon)/600000).toFixed(5),
              lat: parseFloat(parseInt(item.lat)/600000).toFixed(5)
            });
          });
          if (this.mapData.carMarker.show) {
            this.infoWindow.info.address = response.data.transTimeManage.adr;
            this.infoWindow.info.arriveTime = response.data.transTimeManage.estimateArriveTime;
            //最后车辆位置
            this.mapData.lineList.push({
              lng: parseFloat(parseInt(response.data.transTimeManage.lon)/600000).toFixed(5),
              lat: parseFloat(parseInt(response.data.transTimeManage.lat)/600000).toFixed(5)
            });
          }


          //修改地图中心 以及比例
          var zoomObj = getZoom(this.mapData.lineList,this.map);
          this.mapData.center.lat = zoomObj.lat;
          this.mapData.center.lng = zoomObj.lng;
          this.mapData.zoom = zoomObj.zoom;
          const point = new window.BMapGL.Point(zoomObj.lng, zoomObj.lat);
          this.map.centerAndZoom(point, zoomObj.zoom);


          if (null != this.mapData.lineList && this.mapData.lineList.length > 0) {
            //画线
            playMapTraceLine(this.map, this.mapData.lineList);

            //画点 窗口
            var marker1 = addMapMarkerCustom({ map: this.map, latitude:this.mapData.lineList[0].lat, longitude:this.mapData.lineList[0].lng,icon: this.mapData.startMarker.icon})
            var content1 = "发货公司："+this.infoWindow.info.factory+"<br/>车牌号："+this.infoWindow.info.carNumber+"<br/>出厂时间："+this.infoWindow.info.outTime;
            content1 = content1.replace(/undefined/g,"");
            addMapInfoWindow(this.map,marker1,{
              latitude:this.mapData.lineList[0].lat,
              longitude:this.mapData.lineList[0].lng,
              content:content1
            });

            if (this.mapData.carMarker.show) {
              var marker2 = addMapMarkerCustom({ map: this.map, latitude:this.mapData.lineList[this.mapData.lineList.length-1].lat, longitude:this.mapData.lineList[this.mapData.lineList.length-1].lng,icon: this.mapData.carMarker.icon })
              var content2 = "承运人："+this.infoWindow.info.driverName+"("+this.infoWindow.info.driverPhone+")<br/>所在位置："+this.infoWindow.info.address+"<br/>预计到达时间："+this.infoWindow.info.arriveTime;
              content2 = content2.replace(/undefined/g,"");
              addMapInfoWindow(this.map,marker2,{
                latitude:this.mapData.lineList[this.mapData.lineList.length-1].lat,
                longitude:this.mapData.lineList[this.mapData.lineList.length-1].lng,
                content:content2
              });
            }

            var marker3 = addMapMarkerCustom({ map: this.map, latitude: this.mapData.endMarker.lat, longitude: this.mapData.endMarker.lng,icon: this.mapData.endMarker.icon })
            var content3 = "收货地址："+this.infoWindow.info.endAddress+"<br/>签收时间："+this.infoWindow.info.signTime;
            content3 = content3.replace(/undefined/g,"");
            addMapInfoWindow(this.map,marker3,{
              latitude:this.mapData.endMarker.lat,
              longitude:this.mapData.endMarker.lng,
              content:content3
            });

          }

          //this.mapData.lineList = response.data.trackArray;
        } else {
          this.$toast(response.msg);
        }
      }  catch (err) {
        this.$toast(err.message);
      }
    },

    //根据原始数据计算中心坐标和缩放级别，并为地图设置中心坐标和缩放级别。
    /*setZoom(points){
      if(points.length>0){
        var maxLng = points[0].lng;
        var minLng = points[0].lng;
        var maxLat = points[0].lat;
        var minLat = points[0].lat;
        var res;
        for (var i = points.length - 1; i >= 0; i--) {
          res = points[i];
          if(res.lng > maxLng) maxLng =res.lng;
          if(res.lng < minLng) minLng =res.lng;
          if(res.lat > maxLat) maxLat =res.lat;
          if(res.lat < minLat) minLat =res.lat;
        }
        var cenLng =(parseFloat(maxLng)+parseFloat(minLng))/2;
        var cenLat = (parseFloat(maxLat)+parseFloat(minLat))/2;
        var zoom = this.getZoom(maxLng, minLng, maxLat, minLat);
        this.mapData.center.lat = cenLat;
        this.mapData.center.lng = cenLng;
        this.mapData.zoom = zoom;
      }else{
        //没有坐标，显示全中国
        this.mapData.center.lat = '35.563611';
        this.mapData.center.lng = '103.388611';
        this.mapData.zoom = '5';
      }
    },

    //根据经纬极值计算绽放级别。
    getZoom (maxLng, minLng, maxLat, minLat) {
      var zoom = ["50","100","200","500","1000","2000","5000","10000","20000","25000","50000","100000","200000","500000","1000000","2000000"];//级别18到3。
      var pointA = new window.BMap.Point(maxLng,maxLat);  // 创建点坐标A
      var pointB = new window.BMap.Point(minLng,minLat);  // 创建点坐标B
      var distance = new window.BMap.Map("container").getDistance(pointA,pointB).toFixed(1);  //获取两点距离,保留小数点后两位
      for (var i = 0,zoomLen = zoom.length; i < zoomLen; i++) {
        if(zoom[i] - distance > 0){
          return 18-i+3;//之所以会多3，是因为地图范围常常是比例尺距离的10倍以上。所以级别会增加3。
        }
      }
    },*/
    
    
  }
}
</script>

<style lang="scss" scoped>
.map-trace {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .map-container {
    width: 100%;
    flex: 1;
  }
}
</style>
